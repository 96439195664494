<template>
  <div class="user">
    <div class="account">
      <div class="user-info">
        <img class="user-tx" src="@/assets/img/logo.png" />
        <div class="user-right">
          <div class="user-name">{{ abbr(us.address, 4, 4) }}</div>
          <div>级别：{{ us.ulevelName }}</div>
        </div>
      </div>
    </div>

    <div class="wallet">
      <div class="wallet-item" v-for="item in walletList" :key="item.id"
        @click="onClick(item)">
        
        <div class="wallet-name">{{ item.cnameZh }}</div>
        <div>{{ item.jine }}</div>
      </div>
    </div>

    <div class="order">
      <div class="row items-center justify-between">
        <div class="block-title">我的订单</div>
        <div class="more" @click="tolink('shopOrder')">
          全部订单
          <q-icon name="arrow_forward_ios"></q-icon>
        </div>
      </div>
      <div class="order-row">
        <div class="order-item" @click="tolink('shopOrder?state=0')">
          <van-badge :content="showOrdernum(ordernum[0])">
            <div>
              <img src="@/assets/img/user/order1.png" width="27" height="22" />
              <div>待付款</div>
            </div>
          </van-badge>
        </div>
        <div class="order-item" @click="tolink('shopOrder?state=1')">
          <van-badge :content="showOrdernum(ordernum[1])">
            <div>
              <img src="@/assets/img/user/order2.png" width="27" height="22" />
              <div>待发货</div>
            </div>
          </van-badge>
        </div>
        <div class="order-item" @click="tolink('shopOrder?state=2')">
          <van-badge :content="showOrdernum(ordernum[2])">
            <div>
              <img src="@/assets/img/user/order3.png" width="27" height="22" />
              <div>待收货</div>
            </div>
          </van-badge>
        </div>
        <div class="order-item" @click="tolink('shopOrder?state=3')">
          <div>
            <img src="@/assets/img/user/order4.png" width="27" height="22" />
            <div>已收货</div>
          </div>
        </div>
      </div>
    </div>

    <div class="menu">
      <div class="row items-center justify-between">
        <div class="block-title">我的服务</div>
      </div>
      <div class="menu-row">
        <!-- <div class="menu-item" @click="tolink('luckyBuy')">
          <img src="@/assets/img/shop/menu2.png" width="27" />
          <div>幸运购</div>
        </div>
        <div class="menu-item" @click="tolink('luckyBuy?active=3')">
          <img src="@/assets/img/shop/menu2.png" width="27" />
          <div>幸运订单</div>
        </div> -->
        <div class="menu-item" @click="tolink('Share')">
          <img src="@/assets/img/user/share.png" width="27" />
          <div>分享</div>
        </div>
        <div class="menu-item" @click="tolink('address')">
          <img src="@/assets/img/user/address.png" width="27" />
          <div>地址</div>
        </div>
        <div class="menu-item" @click="tolink('problem')">
          <img src="@/assets/img/user/problem.png" width="27" />
          <div>常见问题</div>
        </div>
        <div class="menu-item" @click="tolink('chatList')">
          <img src="@/assets/img/user/service.png" width="27" />
          <div>消息</div>
        </div>
        <!-- <div class="menu-item" @click="merchantClick">
          <img src="@/assets/img/user/merchant.png" width="27" />
          <div>联盟商家</div>
        </div> -->
      </div>
    </div>

    <TabBar active="3"></TabBar>
  </div>
</template>

<script>
import { Badge } from 'vant';
import TabBar from '@/components/TabBar.vue'
import { ref } from 'vue';

export default {
  name: '',
  components: {
    TabBar,
    [Badge.name]: Badge
  },
  setup() {
    return {
      us: ref({}),
      walletList: ref([]),
      ordernum: ref([]),
      shopLevel: ref(0)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getWallet()
    this.getordernum()
    this.getUser()
  },
  methods: {
    onClick(item){
      if(item.cid == 4){
        this.tolink('Bonus')
      } else {
        this.tolink('Wallet')
      }
    },
    tolink(path) {
      this.$router.push(path)
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    showOrdernum(num) {
      if (!num || num == 0) {
        return ""
      } else {
        return num
      }
    },
    merchantClick() {
      if (this.shopLevel == 0) {
        this.$router.push('apply')
      } else {
        this.$router.push('merchant')
      }
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data.us
            _this.shopLevel = data.shopLevel
          }
        }
      )
    },
    getWallet() {
      let _this = this
      _this.$q.loading.show({
        message: _this.$i18n.t("加载中...")
      })
      _this.$request.post(
        "api/Wallets/GetWalletsPageData",
        {
          userid: _this.us.userid
        },
        (res) => {
          _this.$q.loading.hide();
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            })
            return
          }
          let data = res.data.data
          _this.walletList = data
        }
      )
    },
    getordernum() {
      let _this = this
      _this.$request.post(
        "api/ShopOrder/Allordernum",
        {
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            _this.ordernum = data
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.user {
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.account {
  height: 130px;
  padding: 0 14px;
  color: #fff;
  background-color: #ff2d2e;
  background-image: url('~@/assets/img/user/user-header.png');
  background-size: 100% 100%;
}

.user-info {
  padding-top: 20px;
  display: flex;
  align-items: center;
}

.user-right {
  margin-left: 4px;
}

.user-tx {
  margin-right: 9px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.user-name {
  font-size: 17px;
  font-weight: 700;
  color: #fff;
}

.user-name-level {
  padding: 0 5px;
  margin: 5px 10px 0 0;
  border: solid 1px #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
}

.user-ulevel {
  margin-top: 6px;
  font-size: 15px;
  font-weight: 700;
}

.wallet {
  margin: -40px 10px 0;
  padding: 20px 10px;
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.wallet-item {
  flex: 1;
  text-align: center;
}

.wallet-name {
  font-size: 13px;
}

.order {
  margin: 15px 10px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.block-title {
  padding: 6px 0 0;
  font-size: 16px;
  font-weight: bold;
}

.more {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: gray;
}

.order-row {
  margin-top: 15px;
  display: flex;
}

.order-item {
  flex: 1;
  text-align: center;
}

.menu {
  margin: 15px 10px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.menu-row {
  display: flex;
  flex-wrap: wrap;
}

.menu-item {
  margin-top: 15px;
  width: 20%;
  text-align: center;
}
</style>