<template>
  <div class="bar-placeholder"></div>
  <div class="tab-bar">
    <div 
      class="bar-item" 
      :class="{'bar-item-active': active == index}" 
      v-for="(item,index) in list" 
      :key="index"
      @click="tolink(item.url)"
    >
      <img :src="active == index ? item.activeImg : item.img" />
      <div>{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';

export default {
  name: 'TabBar',
  props: {
    active: {
      type: [Number, String],
      default: 0
    }
  },
  components: {},
  setup() {
    return {
      list: ref([])
    }
  },
  created() {
    this.reSet()
  },
  methods: {
    tolink(url) {
      if(url) {
        this.$router.push(url)
      } else {
        Toast('暂未开放');
      }
    },
    reSet() {
      this.list = [
        {
          text: "首页",
          img: require("@/assets/img/tabbar/home.png"),
          activeImg: require("@/assets/img/tabbar/home-active.png"),
          url: "/shop"
        },
        {
          text: "附近",
          img: require("@/assets/img/tabbar/tradearea.png"),
          activeImg: require("@/assets/img/tabbar/tradearea-active.png"),
          url: ""
        },
        {
          text: "购物车",
          img: require("@/assets/img/tabbar/cart.png"),
          activeImg: require("@/assets/img/tabbar/cart-active.png"),
          url: "/shopCart"
        },
        {
          text: "我的",
          img: require("@/assets/img/tabbar/user.png"),
          activeImg: require("@/assets/img/tabbar/user-active.png"),
          url: "/user"
        }
      ]
    }
  }
}
</script>

<style scoped>
  .tab-bar {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
    background-color: #fff;
    border-top: 1px solid rgba(228, 224, 224, .3);
    z-index: 9;
  }

  .bar-item {
    flex: 1;
    text-align: center;
    color: #000;
    font-size: 12px;
  }

  .bar-item-active {
    color: rgba(254, 44, 45, 1);
  }

  .bar-item img {
    /* margin-bottom: 5px; */
    width: 24px;
  }

  .bar-placeholder {
    height: 56px;
  }
</style>